import React from 'react'
import { motion } from 'framer-motion'
import { useMediaQuery } from 'react-responsive'

//CSS
import '../css/ItemPurchaseWindow.css'

//Image
import closeIcon from '../images/closeIcon.png'

import mindfulPurchaseHeaderImage from '../images/mindfulPurchaseHeader.jpg'

function MindfulPurchaseWindow({closeWindow}) {

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    return (
        <div id="purchase-window-main">
            <motion.div animate={{opacity:[0,1], y:[15, 0], transition: {duration: 0.75,}}} id={isMobile ? "item-purchase-container-mobile" : "item-purchase-container-desktop"}>
                <motion.img whileHover={{opacity: [1, 0.5]}} onClick={closeWindow} id={isMobile ? "close-icon-mobile" : "close-icon-desktop" } src={closeIcon}/>
                <div id={isMobile ? "header-container-mobile" : "header-container-desktop"}>
                    <img src={mindfulPurchaseHeaderImage} alt="Blue to Purple Gradient with Mindful Logo in the Center"/>
                    <div id={isMobile ? "header-choice-container-mobile" : "header-choice-container-desktop"}>
                        <h1>Mindful</h1>
                        <p>Mindfulness-based day planning to help you improve mental well-being</p>
                        <div id={isMobile ? "quantity-add-container-mobile" : "quantity-add-container-desktop"} style={{justifyContent: 'flex-end'}}>
                            <motion.button whileHover={{opacity: [1, 0.75]}} id={isMobile ? "add-button-mobile" : "add-button-desktop"} style={{maxWidth: isMobile ? '50%' : '40%', marginTop: "10%"}} onClick={()=>{window.open("https://apps.apple.com/us/app/mindful/id1637254506", "_blank")}}>
                                <p>Download for Free</p>
                            </motion.button>
                        </div>
                    </div>
                </div>
                <div id={isMobile ? "details-container-mobile" : "details-container-desktop"} style={{marginTop: '5%'}}>
                    <section id="overview">
                        <h3>Overview</h3>
                        <p>Mindful is a groundbreaking mobile app designed to transform your daily to-do lists into a structured, stress-reduced schedule. Utilizing an advanced algorithm and natural language processing (NLP), Mindful categorizes tasks and optimizes your day for maximum efficiency and minimum burnout.</p>
                    </section>
                    <section id="keyFeatures">
                        <h3>Key Features</h3>
                        <ol>
                            <li>Intelligent Scheduling Algorithm</li>
                                <ul>
                                    <li>Transforms your daily tasks into a well-organized schedule, balancing "Stressful" (Studying, Class, Work) and "Stress Relieving" (Wellness, Freetime, Extracurricular) activities.</li>
                                </ul>
                            <li>Comprehensive Statistics Tracking</li>
                                <ul>
                                    <li>Nightly check-ins track task completion, punctuality, and more.</li>
                                    <li>View detailed statistics like completion rates, busiest days, and frequently scheduled tags, all filterable by activity type.</li>
                                </ul>
                            <li>Stress Threshold Monitoring</li>
                                <ul>
                                    <li>Evaluates your capacity to handle stressful tasks and alerts you when reaching a potential overload.</li>
                                    <li>Suggests stress-relieving activities to balance your day and maintain efficiency.</li>
                                </ul>
                            <li>Task Efficiency Analysis</li>
                                <ul>
                                    <li>Utilizes performance data to advise on optimal time allocations for various task categories.</li>
                                </ul>
                            <li>Flexible Schedule Management</li>
                                <ul>
                                    <li>Easily delay tasks or the entire day's schedule with custom times</li>
                                </ul>
                            <li>Strict Tasks Feature</li>
                                <ul>
                                    <li>Allows for the creation of recurring tasks on selected days, ensuring routine responsibilities are never overlooked.</li>
                                </ul>
                            <li>Exclusive Student Center</li>
                                <ul>
                                    <li>Tailored features for students, including a study plan generator and a study focus mode with break reminders.</li>
                                    <li>Monitors study duration and discourages phone usage during study periods.</li>
                                </ul>
                            <li>'At a Glance' Home Screen Feature:</li>
                                <ul>
                                    <li>Quick overview of the week's schedule, highlighting free time and potential for additional activities.</li>
                                    <li>Direct navigation to any day's detailed schedule from the home screen.</li>
                                </ul>
                        </ol>
                    </section>
                    <section id="benefits">
                        <h3>Benefits</h3>
                        <ul>
                            <li><strong>Reduced Stress and Burnout: </strong>Ensures a balanced daily schedule, mitigating the risk of stress and burnout.</li>
                            <li><strong>Enhanced Productivity: </strong>Optimizes your time management for effective and efficient task completion.</li>
                            <li><strong>Personalized Planning: </strong>Adapts to your unique work habits and preferences for a customized planning experience.</li>
                            <li><strong>Student-Focused Tools: </strong>Specialized features for academic success and well-being.</li>
                        </ul>
                    </section>
                    <section id="idealfor">
                        <h3>Ideal for</h3>
                        <ul>
                            <li>Individuals seeking a mindful approach to daily planning.</li>
                            <li>Students and professionals looking to balance demanding schedules with personal well-being.</li>
                            <li>Anyone aiming to optimize their time and reduce stress in their daily life.</li>
                        </ul>
                    </section>
                    <section id="reelemin">
                        <h4>Download Mindful now and embark on a journey towards a more organized, stress-free, and productive day.</h4>
                    </section>
                </div>
            </motion.div>
        </div>
    )
}

export default MindfulPurchaseWindow