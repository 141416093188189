import React from 'react'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import ReactGA from 'react-ga4'

//Icons
import { HiExternalLink } from "react-icons/hi";

//Firebase
import { GoogleAuthProvider, getRedirectResult, onAuthStateChanged, signInWithEmailAndPassword, signInWithPopup, signInWithRedirect } from 'firebase/auth'
import { auth, database } from '../firebase/firebaseClient'

//Images
import initialsStoreLogo from '../images/TPSStore.png'
import storeIcon from '../images/storeIcon.png'
import initialsLogo from '../images/TPSLogo+Initials.png'
import generalPFP from '../images/generalPFP.png'
import failedLoad from '../images/failedLoad.png'
import stripeLogoWhite from '../images/stripeLogoWhite.svg'


//CSS
import "../css/Profile.css"
import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore'
import ChangeInformationModal from '../Components/ChangeInformationModal'
import OrderInformation from '../Components/OrderInformation'

const fetchUserOrders = async (uid) => {
  try {
    const userOrdersRef = collection(database, `users/${uid}/user_orders`);
    const querySnapshot = getDocs(userOrdersRef);

    if (querySnapshot.empty) {
      alert('No matching documents.');
      return [];
    }

    const orders = (await querySnapshot).docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return orders;

  } catch (error) {

    alert("Error fetching user orders: " + error);
    return [];

  }
};

function Profile() {
  

  const[isAuthed, setIsAuthed] = useState(false);
  const[firstName, setFirstName] = useState("");
  const[lastName, setLastName] = useState("");
  const[email, setEmail] = useState("");
  const[loginEmail, setLoginEmail] = useState("");
  const[loginPassword, setLoginPassword] = useState("");
  const[phone, setPhone] = useState("");
  const[company, setCompany] = useState("");
  const[googlePFP, setGooglePFP] = useState("");
  const [userOrders, setUserOrders] = useState([]);
  ReactGA.initialize("G-SZLZLTXLRV");
  //Couldnt load profile
  const[loadingError, setLoadingError] = useState(false);

  //Sign In Error Handling
  const [signInError, setSignInError] = useState(false);
  const [signInErrorMsg, setSignInErrorMsg] = useState(false);

  const[changeInfo, setChangeInfo] = useState(false);
  const[changingInfo, setChangingInfo] = useState("");

  const[isGoogleUser, setIsGoogleUser] = useState(false);

  const[showOrderInfo, setShowOrderInfo] = useState(false);
  const[orderInfo, setOrderInfo] = useState({});

  const isMobile = useMediaQuery({
    query: '(max-width: 700px)'
  })

  useEffect(()=>{

    ReactGA.send({
            hitType: "pageview",
            page: "/",
            title: "Profile"
    });

    const user = getRedirectResult(auth);

    if(user == null || user.credential == null){
      alert("There was an error signing into Google. Please try again later.")
    }

    const unsub = onAuthStateChanged(auth, async(user)=>{
      if(user){
        setIsAuthed(true);
        if(isMobile){
          document.getElementById("profile-main-mobile").style.height = "100%";
          document.getElementById("profile-main-mobile").style.paddingTop = "8vh";
        }
        else{
          document.getElementById("profile-main-desktop").style.height = "100%";
          document.getElementById("profile-main-desktop").style.paddingTop = "6vh";
        }

        const resp = await fetchUserInformation();
        if(resp === 500){
          setLoadingError(true);
        }
        if(auth.currentUser.providerData[0].providerId === "google.com"){
          setIsGoogleUser(true);
        }
        else{
          setIsGoogleUser(false);
        }

        fetchUserOrders(auth.currentUser.uid).then((orders)=>{setUserOrders(orders);});
      }
      else{
        setIsAuthed(false);
        if(isMobile){
          document.getElementById("profile-main-mobile").style.height = "100dvh";
          document.getElementById("profile-main-mobile").style.paddingTop = "0";
        }
        else{
          document.getElementById("profile-main-desktop").style.height = "100vh";
          document.getElementById("profile-main-desktop").style.paddingTop = "0";
        }

        document.getElementById("email").addEventListener('focus', ()=>{
          setSignInError(false);
          setSignInErrorMsg("");
        })
        document.getElementById("password").addEventListener('focus', ()=>{
          setSignInError(false);
          setSignInErrorMsg("");
        })
      }
    })

    return () => {
      document.removeEventListener('focus', document.getElementById("email"));
      document.removeEventListener('focus', document.getElementById("password"));
      unsub();
    };

  }, [isMobile, isAuthed]);

  function googleSignIn(){
    setSignInError(false);
    setSignInErrorMsg("");

    if(isMobile){
      signInWithRedirect(auth, new GoogleAuthProvider());
    }
    else{
      signInWithPopup(auth, new GoogleAuthProvider())
      .then((result) => {
        const user = result.user;
        const firstName = user.displayName.split(" ")[0];
        const lastName = user.displayName.split(" ")[1];
        setDoc(doc(database, "users", `${user.uid}`), 
            {userID: user.uid, email: user.email, tel: user.phoneNumber, firstName: firstName, lastName: lastName, company: "", pfp: user.photoURL,
        })
        .catch((error)=>{
          switch (error.code) {
            case 'unauthenticated':
            case 'network-error':
                  setSignInError(true);
                  setSignInErrorMsg("A network error occured. Please try again.");
                  auth.signOut();
                  break;
            default:
              setSignInError(true);
              setSignInErrorMsg("An unexpected error occured. Please try again.");
              auth.signOut();
              break;
          }
        });
      }).catch((error) => {
        switch (error.code) {
          case 'auth/popup-blocked':
              setSignInError(true);
              setSignInErrorMsg("The Google Sign In pop-up was blocked. Please enable pop-ups.");
              break;
          case 'auth/cancelled-popup-request':
          case 'auth/popup-closed-by-user':
              setSignInError(true);
              setSignInErrorMsg("Sign In Pop Up Closed. Please try again.");
              break;
          case 'auth/network-request-failed':
              setSignInError(true);
              setSignInErrorMsg("An network error occured. Please try again.");
              break;
          default:
              setSignInError(true);
              setSignInErrorMsg("An unexpected error occured. Please try again.");
              break;
      }
      });
    }
  }

  function emailAndPasswordSignIn(){
    signInWithEmailAndPassword(auth, loginEmail, loginPassword)
    .then((userCredential) => {
      setLoginEmail("");
      setLoginPassword("");      
    })
    .catch((error) => {
      setSignInError(true);
      switch (error.code) {
        case 'auth/invalid-email':
        case 'auth/wrong-password':
        case 'auth/user-not-found':
          setSignInErrorMsg("Invalid email/password");
          break;
        case 'auth/user-disabled':
          setSignInErrorMsg("Account Disabled. Contact Support");
          break;
        case 'auth/network-request-failed':
          setSignInErrorMsg("Network Error. Please try again.");
          break;
        case 'auth/too-many-requests':
          setSignInErrorMsg("Too many attempts. Please try again later.");
          break;
        case 'auth/internal-error':
        default:
          setSignInErrorMsg("Unexpected Error Occured. Please try again.");
          break;
      }
    });
  }

  function signOutUser(){
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setCompany("");
    setGooglePFP("");
    auth.signOut();
  }

  function fetchUserInformation(){
    return new Promise(async(resolve, reject)=>{
      await getDoc(doc(database, "users", auth.currentUser.uid)).then((data)=>{
          setFirstName(data.data().firstName)
          setLastName(data.data().lastName)
          setEmail(data.data().email)
          setPhone(data.data().tel)
          setCompany(data.data().company)
          setGooglePFP(data.data().pfp);
          resolve(200);
      }).catch((err)=>{
          reject(500);
      })
    })
  }

  async function changeInformation(id){

    window.scrollTo(0, 0);

    switch(id){
      case "n":
        setChangingInfo("Name");
        break;
      case "e":
        setChangingInfo("Email");
        break;
      case "p":
        setChangingInfo("Password");
        break;
      case "t":
        setChangingInfo("Phone");
        break;
      case "c":
        setChangingInfo("Company Name");
        break;
      case "reload":
        const resp = await fetchUserInformation();
        if(resp === 500){
          setLoadingError(true);
        }
        break;
      default:
        break;
    }

    setChangeInfo(!changeInfo);
  }

  function hideShowOrderInfoModal(order){
    if(showOrderInfo){
      document.body.style.overflow = "visible";
    }
    else{
      document.body.style.overflow = "hidden";
    }
    setOrderInfo(order);
    setShowOrderInfo(!showOrderInfo);
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', height: isMobile ? undefined : '100vh'}}>
      <header id="header">
        {!isMobile &&
          <nav className={"store-nav-desktop"}>
            <Link to={"/"} id="nav-logo-desktop" style={{ paddingLeft: 16 }}>
              <img alt="Transparent Software Logo" src={initialsStoreLogo} />
            </Link>
            <div id={"store-nav-links-desktop"}>
              {isAuthed  && <motion.button whileHover={{opacity: [1, 0.65]}} id="signOut-button" onClick={signOutUser}><p>Sign Out</p></motion.button>}
              <Link to={"/store"} id="store-link-container">
                <img id="store-icon-desktop" src={storeIcon} alt="Store Front Icon indicating a link back to the store page"/>
              </Link>
            </div>
          </nav>
        }
        {isMobile &&
          <nav className={"store-nav-mobile"}>
            <Link to={"/store"} id="logo-link-container">
              <img src={initialsStoreLogo} alt="Transparent Software Logo" id="nav-logo-mobile" />
            </Link>
            <div id={"store-nav-links-mobile"}>
              {isAuthed  && <motion.button whileHover={{opacity: [1, 0.65]}} id="signOut-button-mobile" onClick={signOutUser}><p>Sign Out</p></motion.button>}
              <Link to={"/store"} id="store-link-container-mobile">
                <img id="store-icon-mobile" style={{maxWidth: isAuthed ? "70%" : "50%", paddingRight: 8}} src={storeIcon} alt="Store Front Icon indicating a link back to the store page"/>
              </Link>
            </div>
          </nav>
        }
      </header>
      <main id={isMobile ? "profile-main-mobile" : "profile-main-desktop"}>
        {!isAuthed && 
          <motion.div animate={{y: [15, 0], opacity: [0, 1], transition: {duration: 1}}} id={isMobile ? "profile-login-container-mobile" : "profile-login-container-desktop"}>
            <div id={isMobile ? "login-container-mobile" : "login-container-desktop"}>
              <div id={isMobile ? "login-header-mobile" : "login-header-desktop"}>
                <img src={initialsLogo}  alt="Transparent Software Logo"/>
                <h1>Log In</h1>
              </div>
              <form id={isMobile ? "login-form-mobile" : "login-form-desktop"}>
                <label>Email:</label>
                <input id="email" type="text" placeholder="yourEmail@gmail.com" value={loginEmail} onChange={(e)=>{setLoginEmail(e.target.value)}}/>
                <label>Password:</label>
                <input id="password" type="password" placeholder="yourPassword1" value={loginPassword} onChange={(e)=>{setLoginPassword(e.target.value)}}/>
                {signInError && <p class={"error-text"} style={{fontSize: isMobile ? undefined : "0.65vw"}}>{signInErrorMsg}</p>}
              </form>
              <motion.button type="button" whileHover={{opacity: [1, 0.65]}} id={isMobile ? "login-button-mobile" : "login-button-desktop"} onClick={emailAndPasswordSignIn}><p>Log In</p></motion.button>
              <h3>or</h3>
              <button type="button" class={isMobile ? "google-sign-in-button-mobile-profile" : "google-sign-in-button-profile"} onClick={googleSignIn}>
                  Sign in with Google
              </button>
            </div>
          </motion.div>
        }
        {isAuthed && !loadingError && 
          <motion.div animate={{opacity: [0, 1], transition: {duration: 1}}} id={isMobile ? "profile-scroll-container-mobile" : "profile-scroll-container-desktop"}>
            <div class={isMobile ? "profile-information-container-mobile" : "profile-information-container-desktop"}>
              <h2>Profile Information</h2>
              <img id={isMobile ? "profile-image-mobile" : "profile-image-desktop"} referrerPolicy='no-referrer' src={googlePFP === "" ? generalPFP : googlePFP} alt={googlePFP === "" ? "Green Circle with Blank Avatar" : "Google Profile Picture"}/>
              <div id={isMobile ? "profile-information-individual-item-mobile" : "profile-information-individual-item-desktop"}>
                <h4>Name:</h4>
                <p>{firstName} {lastName}</p>
                <motion.button onClick={()=>{changeInformation("n")}} whileHover={{opacity: [1, 0.65]}} id={isMobile ? "profile-change-button-mobile" : "profile-change-button-desktop"}><p>Change</p></motion.button>
              </div>
              <div id={isMobile ? "profile-information-individual-item-mobile" : "profile-information-individual-item-desktop"}>
                <h4>Email:</h4>
                <p>{email}</p>
                <motion.button onClick={()=>{changeInformation("e")}} whileHover={{opacity: isGoogleUser ? [0.25, 0.25] : [1, 0.65]}} id={isMobile ? "profile-change-button-mobile" : "profile-change-button-desktop"} style={{opacity: isGoogleUser ? 0.25 : 1, cursor: isGoogleUser ? "not-allowed" : "pointer"}}><p>Change</p></motion.button>
              </div>
              <div id={isMobile ? "profile-information-individual-item-mobile" : "profile-information-individual-item-desktop"}>
                <h4>Password:</h4>
                <p>***************</p>
                <motion.button onClick={()=>{changeInformation("p")}} whileHover={{opacity: isGoogleUser ? [0.25, 0.25] : [1, 0.65]}} id={isMobile ? "profile-change-button-mobile" : "profile-change-button-desktop"} style={{opacity: isGoogleUser ? 0.25 : 1, cursor: isGoogleUser ? "not-allowed" : "pointer"}}><p>Change</p></motion.button>
              </div>
              <div id={isMobile ? "profile-information-individual-item-mobile" : "profile-information-individual-item-desktop"}>
                <h4>Phone:</h4>
                <p>{phone}</p>
                <motion.button onClick={()=>{changeInformation("t")}} whileHover={{opacity: [1, 0.65]}} id={isMobile ? "profile-change-button-mobile" : "profile-change-button-desktop"}><p>Change</p></motion.button>
              </div>
              <div id={isMobile ? "profile-information-individual-item-mobile" : "profile-information-individual-item-desktop"}>
                <h4>Company:</h4>
                <p>{company}</p>
                <motion.button onClick={()=>{changeInformation("c")}} whileHover={{opacity: [1, 0.65]}} id={isMobile ? "profile-change-button-mobile" : "profile-change-button-desktop"}><p>Change</p></motion.button>
              </div>
            </div>
            <div class={isMobile ? "profile-information-container-mobile" : "profile-information-container-desktop"}>
              <h2>Previous Orders</h2>
              <div id={isMobile ? "order-list-container-mobile" : "order-list-container-desktop"}>
                <h3>Order #:</h3>
                {userOrders.map((order)=>{
                    return(
                      <div key={order.id} id={isMobile ? "order-list-item-mobile" : "order-list-item-desktop"}>
                        <p>{order.id}</p>
                        <motion.button onClick={()=>{hideShowOrderInfoModal(order);}} whileHover={{opacity: [1,0.65]}}>View</motion.button>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div class={isMobile ? "profile-information-container-mobile" : "profile-information-container-desktop"}>
              <h2>Stripe Customer Portal</h2>
              <div id={isMobile ? "stripe-container-mobile" : "stripe-container-desktop"}>
                <h3>Manager Subscriptions here:</h3>
                <motion.button disabled={true} whileHover={{opacity:[0.25,0.25]}} onClick={()=>{window.open("https://billing.stripe.com/p/login/test_3cs5lke7RbbTeY04gg", "_blank")}} style={{opacity: 0.25}}>
                  <img src={stripeLogoWhite}  alt="Stripe Logo"/>
                  <HiExternalLink size={isMobile ? undefined : 20} style={{color: "#FFFFFF"}}/>
                </motion.button>
              </div>
            </div>
          </motion.div>
        }
        {isAuthed && loadingError && 
          <div id={isMobile ? "failedLoad-container-mobile" : "failedLoad-container-desktop"}>
            <img src={failedLoad} id={isMobile ? "failedLoad-image-mobile" : "failedLoad-image-desktop" } alt="Broken TPS Logo with text reading 'Could Not Retrieve Profile Information'"/>
            <p>Please try again later.</p>
          </div>
        }
        {changeInfo && 
          <ChangeInformationModal infoToChange={changingInfo} profileObject={{firstName: firstName, lastName: lastName, email: email, phone: phone, company: company}} close={(e)=>{changeInformation(e);}}/>
        }
        {!isAuthed && <Link to={"/store/createAccount"} id={isMobile ? "create-account-text-mobile" : "create-account-text-desktop"}><p>Don't have an account? <span style={{textDecoration: "underline", color: "#A2BFAE", cursor: 'pointer'}}>Create one here.</span></p></Link>}
      </main>
      {showOrderInfo &&
          <OrderInformation orderObject={orderInfo} close={()=>{hideShowOrderInfoModal();}} translate={document.scrollingElement.scrollTop}/>
      }
    </div>
  )
}

export default Profile