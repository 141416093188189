import { doc, setDoc } from "firebase/firestore";
import { database } from "./firebaseClient";

function generateAlphaNumeric(numKeys, keyLength) {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const keys = [];

    for (let i = 0; i < numKeys; i++) {
        let key = '';
        for (let j = 0; j < keyLength; j++) {
            const randomIndex = Math.floor(Math.random() * chars.length);
            key += chars[randomIndex];
        }
        keys.push(key);
    }

    return keys;
}

export async function gen(amt, email, userID, orderNum) {
    const license_keys = generateAlphaNumeric(amt, 50);

    for (let i = 0; i < license_keys.length; i++) {
        await setDoc(doc(database, "license_keys", license_keys[i]),
            { key: license_keys[i], userEmail: email, assignedDevice: "", paymentActive: true, userID: userID, orderNumber: orderNum}
        )
    }

    return license_keys;
}

