import React from 'react'
import {useState} from 'react'
import { motion } from 'framer-motion'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

//CSS
import '../css/ItemPurchaseWindow.css'

//Image
import closeIcon from '../images/closeIcon.png'

import constellaationPurchaseHeaderImage from '../images/constellationPurchaseHeader.jpg'

function ClashMarkerPurchaseWindow({closeWindow, checkout}) {

    const [productAmount, setProductAmount] = useState(1);
    const [subType, setSubType] = useState("$500.00 / year");

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    function updateProductAmount(e){
        setProductAmount(e.target.value);
    }

    function increaseProductAmt(){
        setProductAmount(oldAmount => oldAmount + 1);
    }

    function decreaseProductAmt(){
        if(productAmount === 0) return;
        setProductAmount(oldAmount => oldAmount - 1);
    }

    function saveToCart(){
        localStorage.removeItem("tps-cart");
        localStorage.setItem("tps-cart", JSON.stringify({productName: "Clash Marker", amt: productAmount, subType: subType}));
    }

    return (
        <div id="purchase-window-main">
            <motion.div animate={{opacity:[0,1], y:[15, 0], transition: {duration: 0.75,}}} id={isMobile ? "item-purchase-container-mobile" : "item-purchase-container-desktop"}>
                <motion.img whileHover={{opacity: [1, 0.5]}} onClick={closeWindow} id={isMobile ? "close-icon-mobile" : "close-icon-desktop" } src={closeIcon}/>
                <div id={isMobile ? "header-container-mobile" : "header-container-desktop"}>
                    <img src={constellaationPurchaseHeaderImage} alt="Navy Blue to Cyan Gradient with Constellation Logo in the Center"/>
                    <div id={isMobile ? "header-choice-container-mobile" : "header-choice-container-desktop"}>
                        <h1>Clash Marker</h1>
                        <p>Automated clash translation from 3D Navisworks files to 2D AutoCAD/BricsCAD files.</p>
                        <select class={isMobile ? "select-style-mobile" : "select-style"} onChange={(e)=>{setSubType(e.target.value);}}>
                            <option value="$500.00 / year">$500.00 <span style={{color: 'red'}}>/ year</span></option>
                            <option value="$45.00 / month">$45.00 <span style={{color: 'red'}}>/ month</span></option>
                        </select>
                        <div id={isMobile ? "quantity-add-container-mobile" : "quantity-add-container-desktop"}>
                            <div id={isMobile ? "quantity-container-mobile" : "quantity-container-desktop"}>
                                <button style={{all: 'unset'}}onClick={decreaseProductAmt}><p>-</p></button>
                                <input placeholder='1' defaultValue={productAmount} value={productAmount} onChange={(e)=>{updateProductAmount(e);}}/>
                                <button style={{all: 'unset'}}onClick={increaseProductAmt}><p>+</p></button>
                                <p style={{marginLeft: '5%', fontSize: isMobile ? "4vw" : '1.5vw'}}>license{productAmount > 1 ? "s" : ""}</p>
                            </div>
                            <Link to={"/store/checkout"} style={{flex: 1, maxWidth: '25%', textDecoration: "none", minHeight: '5%'}}>
                                <motion.button whileHover={{opacity: [1, 0.75]}} onClick={saveToCart} id={isMobile ? "add-button-mobile" : "add-button-desktop"}>
                                    <p>Purchase</p>
                                </motion.button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div id={isMobile ? "details-container-mobile" : "details-container-desktop"}>
                    <section id="overview">
                        <h3>Overview</h3>
                        <p>Clash Marker is an innovative software tool designed to seamlessly integrate Autodesk Navisworks and AutoCAD, as well as its alternative, BricsCAD. This tool revolutionizes the way architects, engineers, and designers handle clash reports in their projects, ensuring precision and efficiency in building design.</p>
                    </section>
                    <section id="keyFeatures">
                        <h3>Key Features</h3>
                        <ol>
                            <li>Seamless Integration with Navisworks and AutoCAD/BricsCAD</li>
                                <ul>
                                    <li>Clash Marker allows users to effortlessly translate complex 3D clash reports from Navisworks into the 2D environments of AutoCAD and BricsCAD.</li>
                                </ul>
                            <li>Comprehensive Clash Report Analysis</li>
                                <ul>
                                    <li>Conduct thorough clash tests in Navisworks, assessing potential collisions between various installations (HVAC, Fire, Structural components, etc.) in a 3D model.</li>
                                    <li>Efficiently process these reports using Clash Marker to identify and address design conflicts.</li>
                                </ul>
                            <li>Effortless Charting on 2D Drawings</li>
                                <ul>
                                    <li>Users can import multiple clash reports into Clash Marker.</li>
                                    <li>The tool charts each identified clash directly onto the 2D drawings, making it easy to locate and resolve potential issues.</li>
                                </ul>
                            <li>Advance Compatability</li>
                                <ul>
                                    <li>Clash Marker is equipped to handle Navisworks files with custom origin points and transforms, ensuring accuracy even when working with models based off non-standard coordinates.</li>
                                </ul>
                            <li>User-Friendly Interface</li>
                                <ul>
                                    <li>A streamlined, intuitive interface allows for easy navigation and use, making it accessible for professionals at all skill levels.</li>
                                </ul>
                        </ol>
                    </section>
                    <section id="benefits">
                        <h3>Benefits</h3>
                        <ul>
                            <li><strong>Enhanced Accuracy: </strong>Minimize errors and rework by precisely identifying and resolving clashes in the early stages of design.</li>
                            <li><strong>Time and Cost Efficiency: </strong>Reduce the time spent on manual translation of clash reports, speeding up the design process and cutting down costs.</li>
                            <li><strong>Improved Collaboration: </strong>Facilitate clearer communication and collaboration among team members by providing easy-to-understand 2D representations of clashes.</li>
                        </ul>
                    </section>
                    <section id="idealfor">
                        <h3>Ideal for</h3>
                        <ul>
                            <li>Architects, Engineers, and Designers seeking efficient solutions for integrating 3D clash reports into 2D designs.</li>
                            <li>Professionals in construction and building design requiring accurate and quick resolution of potential design conflicts.</li>
                        </ul>
                    </section>
                    <section id="reelemin">
                        <h4>Get Clash Marker today and transform the way you handle design clashes, ensuring a smoother, more efficient design process.</h4>
                    </section>
                    <section id="moreInfo" style={{paddingBottom: isMobile ? "5%" : undefined}}>
                        <p>Want to hear more about what went into making Clash Marker? Check out our info page on Clash Marker <Link style={{color: '#508B68'}} to={"/clashmarker"}>here</Link>.</p>
                        <p>Have questions about Clash Marker? Check out our FAQs <Link style={{color: '#508B68'}} to={"/clashmarker"}>here</Link>, or <Link style={{color: '#508B68'}} to={"/contactus"}>contact us</Link>.</p>
                    </section>
                </div>
            </motion.div>
        </div>
    )
}

export default ClashMarkerPurchaseWindow