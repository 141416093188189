import React from 'react'
import {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {motion, useAnimation} from 'framer-motion';
import { Link } from 'react-router-dom';
import axios from 'axios'
import ReactGA from 'react-ga4'

//Components
import MobileNavigationWindow from '../Components/MobileNavigationWindow';
import SiteMap from '../Components/SiteMap';

//Icons
import { HiMenu } from 'react-icons/hi';

//CSS
import '../css/ProductPageMindful.css'
import '../css/ProductPageClashMarker.css'

//Images
import initialsLogo from '../images/TPSLogo+Initials.png'
import tpsFullLogo from '../images/TPSLogo+Name.png'
import phoneSS from '../images/clashMarkerSS.png'
import clashMarkerMockup from '../images/clashMarkerMockup.png'
import autocadLogo from '../images/autocadlogo.png'
import bricscadLogo from '../images/bricscadlogo.png'
import revitLogo from '../images/revitlogo.png'
import hydracadLogo from '../images/HydraCADLogo.png'
import srpinkcadLogo from '../images/SprinkCADLogo.png'

function ProductPageClashMarker() {

    const [isLoaded, setLoading] = useState(false);
    const loadingModal = useAnimation();
    const navModalAnim = useAnimation();
    const [navModal, setNavModal] = useState(false);
    const [email, setEmail] = useState("");
    const [lastSubmittal, setLastSubmittal] = useState(0)
    ReactGA.initialize("G-SZLZLTXLRV");

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    useEffect(()=>{

        ReactGA.send({
            hitType: "pageview",
            page: "/",
            title: "Clash Marker Product Page"
        });

        const body = document.body;
        const previousOverflow = body.style.overflow;
        const previousPaddingRight = body.style.paddingRight;
    
        loadingModal.start("visible");
        navModalAnim.start("visible");

        var image = document.getElementById("title-image");
        const params = new URLSearchParams(window.location.search);

        const scrollbarWidth = window.innerWidth - document.body.offsetWidth;
        document.body.style.paddingRight = `${scrollbarWidth}px`;
        document.body.style.overflowY = "hidden";

        image.onload = function (){
            setTimeout(()=>{
            loadingModal.start("hidden").then(()=>{
                document.body.style.overflowY = "hidden";
                document.body.style.paddingRight = '';
            });
            }, 1500);
        };
    
        body.style.overflow = previousOverflow;
        body.style.paddingRight = previousPaddingRight;

        if (params.get("emaillist") === "true"){
            document.getElementById("email-list").scrollIntoView({behavior: "smooth", inline: "center", block: "center"})
        }
    
    }, [loadingModal, navModalAnim])

    const loadal = {visible: {opacity: [0,1]}, hidden: {opacity: [1, 0], transition: {duration: 1}}}
    const naval = {visible: {opacity: [0,1]}, hidden: {opacity: [1, 0], transition: {duration: 0.5}}}

    function onUpdate(latest){
        if(latest.opacity === 0){
            setLoading(true);
        }
    }

    function showHideModal(){
        if(navModal){
            navModalAnim.start("hidden").then(()=>{
                setNavModal(false);
            });
        }
        else{
            setNavModal(true);
            navModalAnim.start({x: 500});
        }
    }

    function addtoMailingList(){
        if(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email)){
            if(lastSubmittal === 0){
                axios.post("https://newemailforemaillist-cg3iutb5oq-uc.a.run.app", {email_list: "clashmarker", email: email}).then((resp)=>{
                    alert(resp.data.msg)
                })
                .catch((err)=>{
                    console.log(err);
                })
                setLastSubmittal(new Date().getTime())
            }
            else{
                if(new Date().getTime() - lastSubmittal < 5000){
                    alert("You've tried again too quickly. Wait a little and try again.")
                    setLastSubmittal(new Date().getTime())
                }
                else{
                    axios.post("https://newemailforemaillist-cg3iutb5oq-uc.a.run.app", {email_list: "clashmarker", email: email}).then((resp)=>{
                        alert(resp.data.msg)
                    })
                    .catch((err)=>{
                        console.log(err);
                    })
                    setLastSubmittal(new Date().getTime())
                }
            }
        }
        else{
            alert("The email you've entered is in an invalid format. Please enter an email in the following format: youremail@domain.com")
        }

        setEmail("")
    }

    return (
        <div style={{flex: 1, height: '100vh'}}>
            {!isLoaded && 
                <motion.div animate={loadingModal} variants={loadal} id="loadingCover" onUpdate={onUpdate}>
                <motion.img src={tpsFullLogo} id={isMobile ? "loading-cover-img-mobile" : "loading-cover-img"} animate={{opacity: [0, 1], transition: {repeat: Infinity, duration: 1.75, repeatType: "reverse"}}}/>
                </motion.div>
            }
            <header>
                {!isMobile && 
                    <nav className={"nav-desktop"}>
                        <Link to={"/"} id="nav-logo-desktop">
                            <img alt="Transparent Software Logo" src={initialsLogo}/>
                        </Link>
                        <div id={"nav-links-desktop"}>
                            <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/#explore"><p>Our Products</p></motion.a>
                            <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/#whatweoffer"><p>Services</p></motion.a>
                            <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/#whatwedo"><p>What We Do</p></motion.a>
                            <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/contactus"><p>Contact Us</p></motion.a>
                            <Link to={"/store"} id="nav-contact-desktop">
                                <motion.button id="nav-contact-button" whileHover={{backgroundColor: '#FFBE55'}}>
                                    <p style={{color: "#F2F4F3"}}>Shop</p>
                                </motion.button>
                            </Link>
                        </div>
                    </nav>
                }
                {isMobile && 
                    <nav className={"nav-mobile"}>
                        <Link to={"/"} id="logo-link-container">
                            <img src={initialsLogo} alt="Transparent Software Logo" id="nav-logo-mobile"/>
                        </Link>
                        <HiMenu id={"hamburger-icon-mobile"} size={25} onClick={()=> showHideModal()}/>
                    </nav>
                }
            </header>
            {isMobile && navModal && 
                <MobileNavigationWindow naval={naval} navModalAnim={navModalAnim}/>
            }
            <div id="main-container" style={{paddingTop: isMobile ? '8vh' : undefined}}>
                <div className={isMobile ? "title-container-mobile" : "title-container-desktop"}>
                    <div id={isMobile ? "title-container-bg-mobile" : "title-container-bg-desktop"}>
                        <svg id="slanted-artwork" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 483" fill="none" preserveAspectRatio='none'>
                            <path d="M0 0H1440V339L0 483V0Z" fill="#A2BFAE"/>
                        </svg>
                    </div>
                    <div className={isMobile ? "text-image-container-mobile" : "text-image-container-desktop"}>
                        <h1 style={{paddingTop: '5%'}}>Clash Marker</h1>
                        <img style={{paddingLeft: '5%'}} src={clashMarkerMockup} id="title-image" alt="Clash Marker screenshot in front of computer with autocad"/>
                    </div>
                </div>
                <div className={isMobile ? "main-text-container-mobile" : "main-text-container-desktop"}>
                    <div id="email-input" className={isMobile ? "section-container-mobile" : "section-container-desktop"}>
                        <h2>Speed Up Your Workflow, Save Your Time</h2>
                        <p>
                            In the fast-paced world of CAD design, efficiency is key. Clash Marker revolutionizes your workflow by automating one of the most time-consuming aspects of project coordination. Traditionally, identifying and plotting clashes from Navisworks onto a 2D CAD file is a meticulous and slow process, often fraught with human error. Clash Marker eliminates this bottleneck, with its advanced automation capabilities. It seamlessly transfers clash reports directly onto your 2D CAD drawings, or 3D workspaces in Revit. Whether you are working with AutoCAD, BricsCAD, HydraCAD, SprinkCAD, or Revit, Clash Marker integrates effortlessly, turning hours of manual work into a few simple clicks. The results? Faster project turnaround, increased accuracy, and less headaches when it comes to thousands of clashes. Embrace Clash Marker, and watch as your productivity soars, deadlines become more manageable, and your work flow becomes more streamlined than ever before.
                        </p>
                    </div>

                    <iframe style={{width: '100%', height: undefined, aspectRatio: 16/9}} src="https://www.youtube.com/embed/Ojq7J5EFEvM?si=LBC9b9FPVNuRk4yx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

                    <div id="email-list" className={isMobile ? "section-container-mobile" : "section-container-desktop"} style={{position: 'relative', display: isMobile ? undefined : 'flex', flexDirection: isMobile ? undefined : 'row', flex: isMobile ? undefined : 1, justifyContent: isMobile ? undefined : 'flex-start'}}>
                        <h2>Join the Clash Marker Mailing List!</h2>
                        <motion.div id={isMobile ? "emailinputcontainer-mobile" : "emailinputcontainer-desktop"}>
                            <input id={isMobile ? "emailinput-mobile" : "emailinput-desktop"} type="email" value={email} onChange={(e)=>{setEmail(e.target.value);}} placeholder={"youremail@domain.com"}></input>
                            <motion.button whileHover={{opacity: [1, 0.65]}} onClick={()=>{addtoMailingList();}}>Join</motion.button>
                        </motion.div>
                    </div>

                    <div className={isMobile ? "section-container-mobile" : "section-container-desktop"}>
                        <p>Sign up to receive emails about Clash Marker and possibly have the opportunity to <strong>test Clash Marker for free</strong>! You will also receive important information about Clash Marker details, as well as being among the first to find out when Clash Marker will be released!</p>
                    </div>

                    {!isMobile && 
                        <div className={isMobile ? "split-container-mobile" : "split-container-desktop"}>
                            <div className={isMobile ? "text-split-mobile" : "text-split-desktop"}>
                                <div className={isMobile ? "section-container-mobile" : "section-container-desktop"}>
                                    <h2>Why is Clash Marker Needed?</h2>
                                    <p>The CAD industry, especially within the realms of Navisworks, is a complex interplay of multiple disciplines, each with its unique contributions to a unified design. This complexity, however, brings about a common challenge – clashes. These conflicts can derail and slow down a project if not identified and resolved promptly. This is where Clash Marker becomes indispensable! It offers a specialized solution to locating time-consuming clash problems, bridging the gap between clash detection in Navisworks and clash resolution in 2D and 3D CAD environments. By automating the translation of clash reports into actionable insights on your CAD drawings, Clash Marker not only saves time but also enhances accuracy. It ensures that every identified clash is accurately represented on your 2D drawing or 3D model, allowing for a precise and prompt clash resolution. Furthermore, with features supporting custom origins and rotations, Clash Marker is versatile enough to adapt to any project setup, ensuring that no matter the complexity of your file's specifications, the clash data is accurately plotted. In essence, Clash Marker is not just a tool; it's a necessity for anyone seeking to navigate the intricate world of BIM and CAD design with ease and precision.</p>
                                </div>
                            </div>
                            <img src={phoneSS} className={isMobile ? "image-split-mobile" : "image-split-desktop"} style={{minWidth: '40%', objectFit: 'contain', paddingLeft: '2%', alignSelf: 'center'}} alt="Screenshot of the Create Schedule screen in the Mindful Mobile App"/>
                        </div>
                    }

                    {!isMobile && <div className={isMobile ? "section-container-mobile" : "section-container-desktop"}>
                        <h2>Customizable to Your Project's Needs</h2>
                        <p>Clash Marker stands out in its field due to its exceptional adaptability. Recognizing that no two CAD projects are alike, it offers a range of customizable options to cater to specific requirements. Whether you're working with viewpoint reports from BIM coordinators or clash reports generated by your team, Clash Marker flexibly integrates with your workflow. The ability to accommodate custom origin points and rotations is particularly crucial for projects where the Navisworks file doesn’t align with the traditional 0,0,0 origin point. This feature ensures that clashes are accurately translated and plotted, regardless of the project's coordinate system. Additionally, Clash Marker's compatibility with various CAD programs such as AutoCAD, BricsCAD, HydraCAD, SprinkCAD, and Revit further enhances its utility, making it a versatile asset for a wide range of industry professionals. By choosing Clash Marker, you are not just choosing a tool; you are investing in a solution that molds itself to fit the unique contours of your project.</p>                    
                    </div>}

                    {isMobile && 
                        <div className={isMobile ? "split-container-mobile" : "split-container-desktop"}>
                            <div className={isMobile ? "section-container-mobile" : "section-container-desktop"}>
                                <h2>Why is Clash Marker Needed?</h2>
                                <p>The CAD industry, especially within the realms of Navisworks, is a complex interplay of multiple disciplines, each with its unique contributions to a unified design. This complexity, however, brings about a common challenge – clashes. These conflicts can derail and slow down a project if not identified and resolved promptly. This is where Clash Marker becomes indispensable! It offers a specialized solution to locating time-consuming clash problems, bridging the gap between clash detection in Navisworks and clash resolution in 2D and 3D CAD environments. By automating the translation of clash reports into actionable insights on your CAD drawings, Clash Marker not only saves time but also enhances accuracy. It ensures that every identified clash is accurately represented on your 2D drawing or 3D model, allowing for a precise and prompt clash resolution. Furthermore, with features supporting custom origins and rotations, Clash Marker is versatile enough to adapt to any project setup, ensuring that no matter the complexity of your file's specifications, the clash data is accurately plotted. In essence, Clash Marker is not just a tool; it's a necessity for anyone seeking to navigate the intricate world of BIM and CAD design with ease and precision.</p>
                            </div>
                            <img src={phoneSS} className={isMobile ? "image-split-mobile" : "image-split-desktop"} alt="Screenshot of the Create Schedule screen in the Mindful Mobile App"/>
                            <div className={isMobile ? "section-container-mobile" : "section-container-desktop"}>
                                <h2>Customizable to Your Project's Needs</h2>
                                <p>Clash Marker stands out in its field due to its exceptional adaptability. Recognizing that no two CAD projects are alike, it offers a range of customizable options to cater to specific requirements. Whether you're working with viewpoint reports from BIM coordinators or clash reports generated by your team, Clash Marker flexibly integrates with your workflow. The ability to accommodate custom origin points and rotations is particularly crucial for projects where the Navisworks file doesn’t align with the traditional 0,0,0 origin point. This feature ensures that clashes are accurately translated and plotted, regardless of the project's coordinate system. Additionally, Clash Marker's compatibility with various CAD programs such as AutoCAD, BricsCAD, HydraCAD, SprinkCAD, and Revit further enhances its utility, making it a versatile asset for a wide range of industry professionals. By choosing Clash Marker, you are not just choosing a tool; you are investing in a solution that molds itself to fit the unique contours of your project.</p>
                            </div>
                        </div>
                    }

                    <div className={isMobile ? "section-container-mobile" : "section-container-desktop"} style={{paddingTop: 0, marginTop: 0}}>
                        <h2 style={{textAlign: 'center', paddingTop: 0, marginTop: 0, fontSize: isMobile ? '4vw' : '1.1vw'}}>Clash Marker supports:</h2>
                        <div id={isMobile ? "logos-parent-mobile" : "logos-parent-desktop"}>
                            <div className={isMobile ? "logo-container-mobile" : "logo-container-desktop"}>
                                <img src={autocadLogo} alt={"AutoCAD logo"} style={{width: isMobile ? '47%' : '42%'}}/>
                                <p>AutoCAD</p>
                            </div>
                            <div className={isMobile ? "logo-container-mobile" : "logo-container-desktop"}>
                                <img src={revitLogo} alt={"Reivt logo"} style={{width: isMobile ? '47%' : '42%'}}/>
                                <p>Revit</p>
                            </div>
                            <div className={isMobile ? "logo-container-mobile" : "logo-container-desktop"}>
                                <img src={bricscadLogo} alt={"BricsCAD logo"}/>
                                <p>BricsCAD</p>
                            </div>
                            <div className={isMobile ? "logo-container-mobile" : "logo-container-desktop"} style={{marginBottom: 0}}>
                                <img src={hydracadLogo} alt={"HydraCAD logo"} style={{width: isMobile ? '27%' : undefined}}/>
                                <p>HydraCAD</p>
                            </div>
                            <div className={isMobile ? "logo-container-mobile" : "logo-container-desktop"} style={{marginBottom: 0}}>
                                <img src={srpinkcadLogo} alt={"sprinkCAD logo"} style={{width: isMobile ? '60%' : '75%'}}/>
                                <p>sprinkCAD</p>
                            </div>
                        </div>
                    </div>

                    <div className={isMobile ? "section-container-mobile" : "section-container-desktop"} style={{marginBottom: '-5%'}}>
                        <h2>Transparent Software's Commitment: Clarity and Innovation in Every Design</h2>
                        <p>At Transparent Software (TPS), we pride ourselves on clarity and integrity, virtues that are deeply ingrained in every product we make. Our core values of clarity and transparency are deeply ingrained in every aspect of Clash Marker. We understand that the essence of effective collaboration in the CAD industry lies not only in the tools we use but also in the philosophy behind them. That is why Clash Marker is more than just a software application; it is a reflection of our commitment to building reliable, cutting-edge solutions that foster open communication and trust among project teams. Clash Marker embodies these ideals by offering an intuitive, user-friendly interface that demystifies complex processes. By automating the transfer of clash reports into 2D CAD files and 3D workspaces, it provides a clear, unambiguous representation of potential issues, ensuring that every team member, regardless of their technical expertise, can easily understand and act on the information. This transparency is crucial in avoiding misunderstandings and ensuring that all stakeholders are on the same page.
                            <br/>
                            By choosing Clash Marker, developed by Transparent Software, you are not just selecting a tool. You are partnering with a company that values clarity in communication and innovation in design, ensuring that your projects are not only successful but also a true reflection of collaborative excellence. <br></br><br></br> <strong>Clash Marker is a patent pending product.</strong></p>
                    </div>

                    <div className={isMobile ? "section-container-mobile" : "section-container-desktop"} style={{marginBottom: '-0%'}}>
                        <p>Have more questions about Clash Marker? Contact us <strong><Link to={"/contactus"} style={{color: '#508B68'}}>here.</Link></strong></p>
                    </div>

                </div>
                <SiteMap isMobile={isMobile}/>
            </div>
        </div>
    )
}

export default ProductPageClashMarker