import React from 'react'
import { useState, useEffect } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { auth, database } from '../firebase/firebaseClient';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser';
import ReactGA from 'react-ga4'

//Firebase
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';

//Functions
import {gen} from '../firebase/gen.js';

//Images
import TPSLogo from '../images/TPSLogo.png'
import initialsLogo from '../images/TPSLogo+Initials.png'

//CSS
import "../css/OrderConfirmation.css"

async function handleKeys (){
    const params = new URLSearchParams(window.location.search);
    const userOrderDoc = doc(database, "users", `${auth.currentUser.uid}`, "user_orders", `${params.get("orderID")}`);
    //const subDoc = doc(database, "users", `${auth.currentUser.uid}`, "subscriptions", `${params.get("orderID")}`);
    gen(parseInt(params.get("mt")), auth.currentUser.email, auth.currentUser.uid, `${params.get("orderID")}`).then(async (keys)=>{
        await updateDoc(userOrderDoc, {keysGend: true, license_keys: keys, serverTimeStamp: serverTimestamp()});
    });
    
};

const genCheck = async (keysGend)=>{
    if(!keysGend){
        await handleKeys();
    }
};

function OrderConfirmation() {

    const [isProcessing, setIsProcessing] = useState(true);
    const [productName, setProductName] = useState("");
    ReactGA.initialize("G-SZLZLTXLRV");

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    useEffect(()=>{

        ReactGA.send({
            hitType: "pageview",
            page: "/",
            title: "Order Confirmation"
        });

        localStorage.removeItem("tps-cart")

        emailjs.init('zLZlONpT1kLop60cT');

        const unsub = onAuthStateChanged(auth, async(user)=>{
            if(user){

                const params = new URLSearchParams(window.location.search);
                const userDoc = doc(database, "users", `${auth.currentUser.uid}`, "user_orders", `${params.get("orderID")}`);
                let keysGend = true;
                let docInfo = undefined;
                
                await getDoc(userDoc).then(async (doc)=>{
                        productNameSet(doc.data().product);
                        keysGend = doc.data().keysGend;
                        docInfo = doc.data();
                    }).then(async()=>{
                        genCheck(keysGend).then(()=>{
                            setIsProcessing(false);
                        });

                        const serviceID = 'service_k5y3j2c';
                        const templateID = 'template_bmk45cg';
                
                        const templateParams = {
                            to_email: auth.currentUser.email,
                            order_num: `${params.get("orderID")}`,
                            product_name: docInfo.product,
                        }
                
                        if(!docInfo.confirmationEmailSent){

                            emailjs.send(serviceID, templateID, templateParams)
                                .then(async() => {
                                    await updateDoc(userDoc, {confirmationEmailSent: true});
                                    return;
                    
                            }, (err) => {
                                return;
                            });
                        }
                    })
                    .catch((error) => {
                        if(error.code === "permission-denied"){
                            alert("There was an error processing your order. Please contact support.");
                            window.location.href = window.location.origin + "/contactus";
                        }
                        else if(error.code === "not-found"){
                            alert("There was an error processing your order. Please contact support.");
                            window.location.href = window.location.origin + "/contactus";
                        }
                        else{
                            alert("There was an error processing your order. Please contact support.");
                            window.location.href = window.location.origin + "/contactus";
                        }
                    });              
            }
            else{
                window.location.href = window.location.origin + "/store";
            }
        })

        return () => unsub();
    }, [])

    function productNameSet(name){
        setProductName(name);
    }

    return (
        <div>
            {isProcessing && 
                <div id={isMobile ? "processing-container-mobile" : "processing-container-desktop"}>
                    <motion.img animate={{opacity: [0, 1], transition: {duration: 2, repeat: Infinity, repeatType:"reverse"}}} src={TPSLogo} alt="Transparent Software Logo" id="processing-logo"/>
                    <h1>Processing Order...</h1>
                </div>
            }
            <header id="header">
                    {!isMobile && 
                        <nav className={"store-nav-desktop"}>
                            <Link to={"/"} id="nav-logo-desktop" style={{paddingLeft: 16}}>
                                <img alt="Transparent Software Logo" src={initialsLogo}/>
                            </Link>
                        </nav>
                    }
                    {isMobile && 
                        <nav className={"store-nav-mobile"}>
                            <Link to={"/"} id="logo-link-container">
                                <img src={initialsLogo} alt="Transparent Software Logo" id="nav-logo-mobile"/>
                            </Link>
                        </nav>
                    }
            </header>
            <main id={isMobile ? "orderConfirm-main-mobile" : "orderConfirm-main-desktop"}>
                <div id={isMobile ? "order-header-container-mobile" : "order-header-container-desktop"}>
                    <h1>Thank You for Your Purchase!</h1>
                    <h2>Order <span style={{color: "#508B68"}}>#{new URLSearchParams(window.location.search).get("orderID")}</span></h2>
                    <h2>Product: {productName}</h2>
                    <h3>What Happens Next?</h3>
                    <ol>
                        <li><strong>Order Confirmation Email:</strong> Check your inbox for an email confirmation. It contains your order details and receipt.</li>
                        <li><strong>Download and Installation:</strong></li>
                        <ul>
                            <li>Your confirmation email will have instructions on how to download your product, or you can go straight to your account downloadables <Link to={"/store/profile"}><span style={{textDecoration: "underline", color: "#508B68", cursor: "pointer"}}>here</span>.</Link></li>
                            <li>Follow the easy steps to get Clash Marker up and running on your system.</li>
                        </ul>
                        <li><strong>Getting Started:</strong></li>
                        <ul>
                            {productName === "Clash Marker" && <li>After installation, dive into our user-friendly interface and begin integrating your Navisworks clash reports with AutoCAD or BricsCAD.</li>}
                            <li>Need a hand getting started? Our comprehensive user guide and support resources can be found <Link to={"/faq"} style={{color: "#508B68"}}>here</Link>.</li>
                        </ul>
                        <li><strong>Stay Updated:</strong></li>
                        <ul>
                            <li>We'll keep you in the loop with regular updates and enhancements to ensure Clash Marker always meets your needs.</li>
                        </ul>
                    </ol>
                    <Link to={"/store/profile"} style={{textDecoration:"underline", color: "#508B68"}}><p>Go to Store Profile</p></Link>
                    <Link to={"/store"} style={{textDecoration:"underline", color: "#508B68"}}><p>Return to the Store</p></Link>
                </div>
            </main>
        </div>  
    )
}

export default OrderConfirmation