import React from 'react'
import {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {motion, useAnimation} from 'framer-motion';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4'

//Icons
import { HiMenu } from 'react-icons/hi';

//CSS
import '../css/ProductPageMindful.css'

//Images
import initialsLogo from '../images/TPSLogo+Initials.png'
import mindfulTitleImage from '../images/mindfulTitleImg.png'
import tpsFullLogo from '../images/TPSLogo+Name.png'
import phoneSS from '../images/mindfulProductSS.jpg'
import MobileNavigationWindow from '../Components/MobileNavigationWindow';
import SiteMap from '../Components/SiteMap';

function ProductPageMindful() {

    const [isLoaded, setLoading] = useState(false);
    const loadingModal = useAnimation();
    const navModalAnim = useAnimation();
    const [navModal, setNavModal] = useState(false);
    ReactGA.initialize("G-SZLZLTXLRV");

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    useEffect(()=>{

        ReactGA.send({
            hitType: "pageview",
            page: "/",
            title: "Mindful Product Page"
        });

        const body = document.body;
        const previousOverflow = body.style.overflow;
        const previousPaddingRight = body.style.paddingRight;
    
        loadingModal.start("visible");
        navModalAnim.start("visible");

        var image = document.getElementById("title-image");

        const scrollbarWidth = window.innerWidth - document.body.offsetWidth;
        document.body.style.paddingRight = `${scrollbarWidth}px`;
        document.body.style.overflowY = "hidden";

        image.onload = function (){
            setTimeout(()=>{
            loadingModal.start("hidden").then(()=>{
                document.body.style.overflowY = "hidden";
                document.body.style.paddingRight = '';
            });
            }, 1500);
        };
    
        body.style.overflow = previousOverflow;
        body.style.paddingRight = previousPaddingRight;
    
    }, [loadingModal, navModalAnim])

    const loadal = {visible: {opacity: [0,1]}, hidden: {opacity: [1, 0], transition: {duration: 1}}}
    const naval = {visible: {opacity: [0,1]}, hidden: {opacity: [1, 0], transition: {duration: 0.5}}}

    function onUpdate(latest){
        if(latest.opacity === 0){
            setLoading(true);
        }
    }

    function showHideModal(){
        if(navModal){
            navModalAnim.start("hidden").then(()=>{
                setNavModal(false);
            });
        }
        else{
            setNavModal(true);
            navModalAnim.start({x: 500});
        }
    }

    return (
        <div style={{flex: 1, overflow: "visible", height: '100vh'}}>
            {!isLoaded && 
                <motion.div animate={loadingModal} variants={loadal} id="loadingCover" onUpdate={onUpdate}>
                <motion.img src={tpsFullLogo} id={isMobile ? "loading-cover-img-mobile" : "loading-cover-img"} animate={{opacity: [0, 1], transition: {repeat: Infinity, duration: 1.75, repeatType: "reverse"}}}/>
                </motion.div>
            }
            <header>
                {!isMobile && 
                    <nav className={"nav-desktop"}>
                        <Link to={"/"} id="nav-logo-desktop">
                            <img alt="Transparent Software Logo" src={initialsLogo}/>
                        </Link>
                        <div id={"nav-links-desktop"}>
                        <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/#explore"><p>Our Products</p></motion.a>
                        <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/#whatweoffer"><p>Services</p></motion.a>
                        <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/#whatwedo"><p>What We Do</p></motion.a>
                        <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/contactus"><p>Contact Us</p></motion.a>
                        <Link to={"/store"} id="nav-contact-desktop">
                            <motion.button id="nav-contact-button" whileHover={{backgroundColor: '#FFBE55'}}>
                                <p style={{color: "#F2F4F3"}}>Shop</p>
                            </motion.button>
                        </Link>
                        </div>
                    </nav>
                }
                {isMobile && 
                    <nav className={"nav-mobile"}>
                        <Link to={"/"} id="logo-link-container">
                            <img src={initialsLogo} alt="Transparent Software Logo" id="nav-logo-mobile"/>
                        </Link>
                        <HiMenu id={"hamburger-icon-mobile"} size={25} onClick={()=> showHideModal()}/>
                    </nav>
                }
            </header>
            {isMobile && navModal && 
                <MobileNavigationWindow naval={naval} navModalAnim={navModalAnim}/>
            }
            <div id="main-container" style={{paddingTop: isMobile ? '8vh' : undefined}}>
                <div className={isMobile ? "title-container-mobile" : "title-container-desktop"}>
                    <div id={isMobile ? "title-container-bg-mobile" : "title-container-bg-desktop"}>
                        <svg id="slanted-artwork" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 483" fill="none" preserveAspectRatio='none'>
                            <path d="M0 0H1440V339L0 483V0Z" fill="#A2BFAE"/>
                        </svg>
                    </div>
                    <div className={isMobile ? "text-image-container-mobile" : "text-image-container-desktop"}>
                        <h1>Mindful Mobile App</h1>
                        <img src={mindfulTitleImage} id="title-image" alt="iPhone renderings of the Mindful Mobile App"/>
                    </div>
                </div>
                <div className={isMobile ? "main-text-container-mobile" : "main-text-container-desktop"}>
                    <div className={isMobile ? "section-container-mobile" : "section-container-desktop"}>
                        <h2>Modern Problems, Require Complicated Solutions</h2>
                        <p>In a world where the demands of modern life constantly push our limits, the prevalence of stress among college students has escalated to an all-time high. With nearly 90% of students navigating a high-stress environment, the complexity of challenges we face today demands equally nuanced solutions. Mindful emerges as an innovative beacon in this landscape, redefining the way we manage our daily lives. Its algorithm is not just a scheduling tool; it's a personal assistant that understands the ebbs and flows of student life, making complexity manageable and wellness achievable.</p>
                    </div>

                    {!isMobile && 
                        <div className={isMobile ? "split-container-mobile" : "split-container-desktop"}>
                            <div className={isMobile ? "text-split-mobile" : "text-split-desktop"}>
                                <div className={isMobile ? "section-container-mobile" : "section-container-desktop"}>
                                    <h2>Why is Mindful Needed?</h2>
                                    <p>Mindful is the answer to the student's plea for balance. It transcends the traditional confines of time-management apps by placing mental well-being at the forefront. With its intuitive design, Mindful encourages the formation of beneficial habits, elevating daily efficiency while granting users the precious gift of time. By fine-tuning one's lifestyle to include stress-relieving activities, Mindful doesn’t just organize a schedule—it curates a lifestyle conducive to academic excellence and personal growth.</p>
                                </div>

                                <div className={isMobile ? "section-container-mobile" : "section-container-desktop"}>
                                    <h2>Overcoming Challenges with Mindful</h2>
                                    <p>At the heart of Mindful's innovative approach is its unique algorithm, supported by research on stress and its impacts on our lives. It promotes healthy routines, seamlessly integrating them into users' schedules to foster a more structured and mentally beneficial day. Progress tracking is a cornerstone feature, allowing users to see just how far they've come since embarking on their Mindful journey. By alleviating the burden of time management, Mindful empowers users to focus on what truly matters, navigating the complexities of college life with confidence and poise.</p>
                                </div>
                            </div>
                            <img src={phoneSS} className={isMobile ? "image-split-mobile" : "image-split-desktop"} alt="Screenshot of the Create Schedule screen in the Mindful Mobile App"/>
                        </div>
                    }

                    {isMobile && 
                        <div className={isMobile ? "split-container-mobile" : "split-container-desktop"}>
                            <div className={isMobile ? "section-container-mobile" : "section-container-desktop"}>
                                <h2>Why is Mindful Needed?</h2>
                                <p>Mindful is the answer to the student's plea for balance. It transcends the traditional confines of time-management apps by placing mental well-being at the forefront. With its intuitive design, Mindful encourages the formation of beneficial habits, elevating daily efficiency while granting users the precious gift of time. By fine-tuning one's lifestyle to include stress-relieving activities, Mindful doesn’t just organize a schedule—it curates a lifestyle conducive to academic excellence and personal growth.</p>
                            </div>
                            <img src={phoneSS} className={isMobile ? "image-split-mobile" : "image-split-desktop"} alt="Screenshot of the Create Schedule screen in the Mindful Mobile App"/>
                            <div className={isMobile ? "section-container-mobile" : "section-container-desktop"}>
                                <h2>Overcoming Challenges with Mindful</h2>
                                <p>At the heart of Mindful's innovative approach is its unique algorithm, supported by research on stress and its impacts on our lives. It promotes healthy routines, seamlessly integrating them into users' schedules to foster a more structured and mentally beneficial day. Progress tracking is a cornerstone feature, allowing users to see just how far they've come since embarking on their Mindful journey. By alleviating the burden of time management, Mindful empowers users to focus on what truly matters, navigating the complexities of college life with confidence and poise.</p>
                            </div>
                        </div>
                    }

                    <div className={isMobile ? "section-container-mobile" : "section-container-desktop"} style={{marginBottom: '10%'}}>
                        <h2>Transparent in Our Approach</h2>
                        <p>At Transparent Software (TPS), we pride ourselves on clarity and integrity, virtues that are deeply ingrained in our first app store product, Mindful. The genesis of Mindful was marked by an ambitious vision to craft an application that stands as a testament to our commitment to user-centric design and innovation. From conception to publication, a timespan of two years, our dedicated team was immersed in every detail of its creation.
                            Harnessing the versatility of the React Native framework, Mindful was brought to life through a process that blended technological prowess with human-centered insights. Extensive UX research guided our design, ensuring that every interaction with the app felt intuitive and responsive. Parallelly, medical research informed our features, embedding evidence-based practices into the app's core functionality.
                            <br/>
                            <br/>
                            TPS's hands-on approach didn't stop at design and development. Our UI was sculpted with the user's visual and navigational comfort in mind, creating an interface that is not only aesthetically pleasing but also conducive to a stress-reducing experience. The journey from a nascent idea to a fully-fledged tool in the app store was propelled by our in-house talent, reflecting TPS's holistic capabilities in nurturing and realizing digital solutions.
                            <br/>
                            <br/>
                            Mindful is more than just an app; it's the culmination of Transparent Software's ethos—creating products that are not just transparent in their use but also in their development journey. By overseeing every stage, from the initial ideation, through rigorous research and design phases, to the final release, TPS ensures that Mindful is a product truly reflective of our name—transparent, reliable, and purpose-driven.</p>
                    </div>
                </div>
                <SiteMap isMobile={isMobile}/>
            </div>
        </div>
    )
}

export default ProductPageMindful