import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { motion } from 'framer-motion';

//CSS
import "../css/ProductContainer.css"

//Images
import plusIcon from "../images/plusIcon.png"

function ProductItem({image, productName, unavailable, description, openWindow}) {

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    return (
        <motion.div whileHover={unavailable || isMobile ? {} : {y: [0, -5], transition: {duration: 1}}} id={isMobile ? "item-container-mobile" : "item-container-desktop"}>
            {unavailable && 
                <div style={{position: 'absolute', width: '100%', height: '100%', backgroundColor: "#E1E1E1", opacity: 0.5}}><p></p></div>
            }
            <div class={isMobile ? "upperHalf-container-mobile" : "upperHalf-container-desktop"}>
                <img src={image} alt={`${productName} Product Banner`}/>
            </div>
            <div class={isMobile ? "lowerHalf-container-mobile" : "lowerHalf-container-desktop"}>
                <h3>{productName}</h3>
                <div>
                    <p>{description}</p>
                    <motion.img onClick={()=>{if(!unavailable){openWindow(productName);}}} whileHover={unavailable ? {} : {opacity: [1, 0.7]}} src={plusIcon} style={{opacity: unavailable ? 0.25 : 1, cursor: unavailable ? "not-allowed" : "pointer"}}/>
                </div>
            </div>
        </motion.div>
    )
    
}

export default ProductItem;
