import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { motion } from 'framer-motion'

//CSS
import '../css/Profile.css'

//Images
import closeIcon from '../images/closeIcon.png'
import confusedScientist from '../images/confusedScientist.png';

//Icons
import { HiDownload } from "react-icons/hi";
import { HiCheck } from "react-icons/hi";
import CopyKeyButton from './CopyKeyButton'

function OrderInformation({ orderObject, close, translate }) {

    const isMobile = useMediaQuery({ query: '(max-width: 700px)' })
    const [copyingAllKeys, setCopyingAllKeys] = useState(false);
    const [copyError, setCopyError] = useState(false);
    const [errorKey, setErrorKey] = useState("");

    function addCommas(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function copyAllKeys() {
        let keyString = orderObject.license_keys.join("\n");
        setCopyingAllKeys(true);
        
        try{
            navigator.clipboard.writeText(keyString).catch((err) => {
                setCopyError(true);
                setErrorKey(keyString);
                return;
            });
        }catch(err){
            setCopyError(true);
            setErrorKey(keyString);
        }

        setTimeout(()=>{setCopyingAllKeys(false)}, 2500);
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', width: '100%', height: '100lvh', backgroundColor: 'rgba(0,0,0,0.25)', zIndex: 15, transform: `translateY(${translate}px)`}}>
            <div id={isMobile ? 'order-information-container-mobile' : 'order-information-container-desktop'}>
                <motion.img whileHover={{ opacity: [1, 0.65] }} src={closeIcon} onClick={close} />
                <h2>Order #<span style={{ color: '#A2BFAE' }}>{orderObject.id}</span></h2>
                <h3>Product: <span style={{ fontWeight: 400 }}>{orderObject.product}</span></h3>
                <h3>Quantity: <span style={{ fontWeight: 400 }}>{orderObject.quantity}</span></h3>
                <h3>Subscription Type: <span style={{ fontWeight: 400 }}>{orderObject.subType.substring(0, 2).toUpperCase() + orderObject.subType.substring(2)}</span></h3>
                <h3>Total: <span style={{ fontWeight: 400 }}>${addCommas(orderObject.total)}.00</span></h3>
                <div id={isMobile ? 'license-keys-header-mobile' : 'license-keys-header-desktop'}>
                    <h3>Product Keys:</h3>
                    {!copyingAllKeys && <motion.button whileHover={{ opacity: [1, 0.65] }} onClick={copyAllKeys}>
                        Copy All <HiDownload style={{ transform: 'translateY(5%)' }} />
                    </motion.button>}
                    {copyingAllKeys && <motion.button whileHover={{ opacity: [1, 0.65] }} onClick={copyAllKeys}>
                        Copied All <HiCheck style={{ transform: 'translateY(5%)' }} />
                    </motion.button>}
                </div>
                <div id={isMobile ? 'license-keys-container-mobile' : 'license-keys-container-desktop'}>
                    {orderObject.license_keys.map(key => {
                        return (
                            <div class={isMobile ? "key-container-mobile" : "key-container-desktop"}>
                                <div class={isMobile ? "key-cover-mobile" : "key-cover-desktop"}><p> </p></div>
                                <p key={key}>{key.substring(0, 30)}...</p>
                                <CopyKeyButton licenseKey={key} error={(errorState, key)=>{setCopyError(errorState); setErrorKey(key);}}/>
                            </div>
                        )
                    })
                    }
                </div>
            </div>
            {copyError &&
                <div id={isMobile ? "copy-error-bg-mobile" : "copy-error-bg-desktop"}>
                    <div id={isMobile ? "copy-error-container-mobile" : "copy-error-container-desktop"}>
                        <motion.img id={isMobile ? "copy-error-close-mobile" : "copy-error-close-desktop"} whileHover={{ opacity: [1, 0.65] }} src={closeIcon} onClick={()=>{setCopyError(false); setErrorKey("");}} alt="Circle with X in the middle for exiting"/>
                        <img src={confusedScientist} alt="Confused Scientists scratching their head"/>
                        <h4>There was an error automatically copying your key(s) to the clipboard! Please copy it manually below:</h4>
                        <p>{errorKey}</p>
                    </div>
                </div>
            }
        </div>
    )
}

export default OrderInformation