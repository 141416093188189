import React, { useCallback } from 'react'
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import { motion } from 'framer-motion';

//Images
import closeIcon from "../images/closeIcon.png";

//CSS
import "../css/Profile.css"
import { doc, updateDoc } from 'firebase/firestore';
import { auth, database, firebaseAuth } from '../firebase/firebaseClient';

function ChangeInformationModal({ infoToChange, profileObject, close }) {

    //General Profile Info
    const oldFirstName = profileObject.firstName;
    const [firstName, setFirstName] = useState(profileObject.firstName);
    const oldLastName = profileObject.lastName;
    const [lastName, setLastName] = useState(profileObject.lastName);

    //New Email Handling
    const oldEmail = profileObject.email;
    const [email, setEmail] = useState(profileObject.email);
    const [emailPass, setEmailPass] = useState("");
    const oldPhone = profileObject.phone;
    const [phone, setPhone] = useState(profileObject.phone || "");
    const oldCompany = profileObject.company;
    const [company, setCompany] = useState(profileObject.company || "");

    //New Password Handling
    const [currentPassword, setCurrentPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    //Double-Click Button Protection
    const [processing, setProcessing] = useState(false);

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    });

    const initializeEventListeners = useCallback(()=> {
        if (infoToChange === "Name") {
            document.getElementById("fName").addEventListener("focus", (e) => {
                document.getElementById("fName").style.border = "none";
            });

            document.getElementById("lName").addEventListener("focus", (e) => {
                document.getElementById("lName").style.border = "none";
            });
        }
        else if (infoToChange === "Email") {
            document.getElementById("email").addEventListener("focus", (e) => {
                document.getElementById("email").style.border = "none";
            });

            document.getElementById("emailPassword").addEventListener("focus", (e) => {
                document.getElementById("emailPassword").style.border = "none";
            });
        }
        else if (infoToChange === "Password") {
            
            document.getElementById("currentPassword").addEventListener("focus", (e) => {
                document.getElementById("currentPassword").style.border = "none";
            });

            document.getElementById("newPassword").addEventListener("focus", (e) => {
                document.getElementById("newPassword").style.border = "none";
            });

            document.getElementById("confirmNewPassword").addEventListener("focus", (e) => {
                document.getElementById("confirmNewPassword").style.border = "none";
            });
        }
        else if (infoToChange === "Phone") {
            document.getElementById("phone").addEventListener("focus", (e) => {
                document.getElementById("phone").style.border = "none";
                document.getElementById("phone").attributes.getNamedItem("placeholder").value  = "777-777-7777";
            });
        }
        else if (infoToChange === "Company Name") {
            document.getElementById("company").addEventListener("focus", (e) => {
                document.getElementById("company").style.border = "none";
            });
        }
    }, [infoToChange]);

    useEffect(() => {
        switch (infoToChange) {
            case "Phone":
            case "Company Name":
                if(isMobile){
                    document.getElementById("changeInfo-container-mobile").style.maxHeight = "35%";
                }
                else{
                    document.getElementById("changeInfo-container-desktop").style.maxHeight = "22%";
                }
                break;
            case "Password":
                if(isMobile){
                    document.getElementById("changeInfo-container-mobile").style.maxHeight = "52%";
                }
                else{
                    document.getElementById("changeInfo-container-desktop").style.maxHeight = "36%";
                }
                break;
            default:
                break;
        }

        initializeEventListeners();

        return () => {
            document.removeEventListener("focus", document.getElementById("fName"));
            document.removeEventListener("focus", document.getElementById("lName"));
            document.removeEventListener("focus", document.getElementById("email"));
            document.removeEventListener("focus", document.getElementById("emailPassword"));
            document.removeEventListener("focus", document.getElementById("currentPassword"));
            document.removeEventListener("focus", document.getElementById("newPassword"));
            document.removeEventListener("focus", document.getElementById("confirmNewPassword"));
            document.removeEventListener("focus", document.getElementById("phone"));
        };

    }, [infoToChange, initializeEventListeners, isMobile]);


    function updateBackend() {
        setProcessing(true);

        switch (infoToChange) {
            case "Name":
                updateNameInformation();
                break;
            case "Email":
                updateEmailInformation();
                break;
            case "Password":
                updatePassword();
                break;
            case "Phone":
                updatePhone();
                break;
            case "Company Name":
                updateCompanyInformation();
                break;
            default:
                break;
        }
    }

    async function updateNameInformation() {

        let errorCheck = false;

        if (firstName.length === 0) {
            document.getElementById("fName").style.border = "1px solid red";
            errorCheck = true;
        }

        if (lastName.length === 0) {
            document.getElementById("lName").style.border = "1px solid red";
            errorCheck = true;
        }

        if (errorCheck) {
            return;
        }

        if (firstName === oldFirstName && lastName === oldLastName) {
            close();
        }

        await updateDoc(doc(database, "users", `${auth.currentUser.uid}`), {firstName: firstName, lastName: lastName}).then(()=>{
            close();
        }).catch((err)=>{
            alert("Could not update name information. Please try again later.");
        });

    }

    async function updateEmailInformation() {

        let errorCheck = false;

        if (email.length === 0) {
            document.getElementById("email").style.border = "1px solid red";
            errorCheck = true;
        }

        if (emailPass.length === 0) {
            document.getElementById("emailPassword").style.border = "1px solid red";
            errorCheck = true;
        }

        if(errorCheck){
            return;
        }

        if (email === oldEmail) {
            close();
        }

        const credential = firebaseAuth.EmailAuthProvider.credential(auth.currentUser.email, emailPass);
        firebaseAuth.reauthenticateWithCredential(auth.currentUser, credential).then(()=>{
            firebaseAuth.updateEmail(auth.currentUser, email).then(async (promise)=>{
                await updateDoc(doc(database, "users", `${auth.currentUser.uid}`), {email: email}).then(()=>{
                    close("reload");
                }).catch((err)=>{
                    alert("Could not update email information. Please try again later.");
                });
            })
            .catch((err)=>{
                alert("Could not update email information. Please try again later.");
            })
        })
        .catch((err)=>{
            alert("There was an error confirming your password. Please try again.")
        })

    }

    async function updatePassword() {

        let errorCheck = false;

        if (currentPassword.length === 0) {
            document.getElementById("currentPassword").style.border = "1px solid red";
            errorCheck = true;
        }

        if (password.length === 0) {
            document.getElementById("newPassword").style.border = "1px solid red";
            errorCheck = true;
        }

        if (confirmPassword.length === 0) {
            document.getElementById("confirmNewPassword").style.border = "1px solid red";
            errorCheck = true;
        }

        if (errorCheck) {
            return;
        }

        const credential = firebaseAuth.EmailAuthProvider.credential(auth.currentUser.email, currentPassword);
        firebaseAuth.reauthenticateWithCredential(auth.currentUser, credential).then(()=>{
            firebaseAuth.updatePassword(auth.currentUser, confirmPassword).then(async(promise)=>{
                alert("Your password has been updated!");
                close();
            })
            .catch((err)=>{
                alert("There was an error updating your password. Please try again later.")
            })
        })
        .catch((err)=>{
            alert("There was an error confirming your current password. Please try again.")
        })
    }

    async function updatePhone() {
        if (phone.length === 0) {
            document.getElementById("phone").style.border = "1px solid red";
            return;
        }

        if (phone === oldPhone) {
            close();
        }

        if(/^\d{3}-\d{3}-\d{4}$/.test(phone) || /^\d{10}$/.test(phone)){
            await updateDoc(doc(database, "users", `${auth.currentUser.uid}`), {tel: phone}).then(()=>{
                close("reload");
            }).catch((err)=>{
                alert("Could not update phone information. Please try again later.")
            });
        }
        else{
            document.getElementById("phone").style.border = "1px solid red";
            setPhone("");
            document.getElementById("phone").attributes.getNamedItem("placeholder").value  = "Phone number should follow this format: XXX-XXX-XXXX";
            return;
        }
    }

    async function updateCompanyInformation() {
        if (company.length === 0) {
            document.getElementById("company").style.border = "1px solid red";
            return;
        }

        if (company === oldCompany) {
            close();
        }

        await updateDoc(doc(database, "users", `${auth.currentUser.uid}`), {company: company}).then(()=>{
            close("reload");
        }).catch((err)=>{
            alert("Could not update company information. Please try again later.")
        });
    }

    return (
        <div id={isMobile ? "changeInfo-main-mobile" : "changeInfo-main-desktop"}>
            <div id={isMobile ? "changeInfo-container-mobile" : "changeInfo-container-desktop"}>
                <motion.img whileHover={{ opacity: [1, 0.5] }} onClick={close} id={isMobile ? "close-icon-info-mobile" : "close-icon-info-desktop"} src={closeIcon} />
                <h3>Change {infoToChange}</h3>
                {infoToChange === "Name" &&
                    <form>
                        <label for="fName">First Name:</label>
                        <input id="fName" type="text" value={firstName} onChange={(e) => { setFirstName(e.target.value) }} />
                        <label for="lName">Last Name:</label>
                        <input id="lName" type="text" value={lastName} onChange={(e) => { setLastName(e.target.value) }} />
                    </form>
                }
                {infoToChange === "Email" &&
                    <form>
                        <label for="email">Email:</label>
                        <input id="email" type="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                        <label for="emailPassword">Password:</label>
                        <input id="emailPassword" type="password" value={emailPass} onChange={(e)=>{ setEmailPass(e.target.value) }}/>
                    </form>
                }
                {infoToChange === "Password" &&
                    <form>
                        <label for="currentPassword">Current Password:</label>
                        <input id="currentPassword" type="password" value={currentPassword} onChange={(e)=>{setCurrentPassword(e.target.value)}}/>
                        <label for="newPassword">New Password:</label>
                        <input id="newPassword" type="password" value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
                        <label for="confirmNewPassword">Confirm New Password:</label>
                        <input id="confirmNewPassword" type="password" value={confirmPassword} onChange={(e)=>{setConfirmPassword(e.target.value)}}/>
                    </form>
                }
                {infoToChange === "Phone" &&
                    <form>
                        <label for="phone">Phone:</label>
                        <input id="phone" type="text" value={phone} placeholder="" onChange={(e) => { setPhone(e.target.value) }} />
                    </form>
                }
                {infoToChange === "Company Name" &&
                    <form>
                        <label for="company">Company Name:</label>
                        <input id="company" type="text" value={company} onChange={(e) => { setCompany(e.target.value) }} />
                    </form>
                }
                <motion.button disabled={processing} whileHover={{ opacity: [1, 0.65] }} onClick={updateBackend}>Confirm</motion.button>
            </div>
        </div>
    )
}

export default ChangeInformationModal