import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { motion } from 'framer-motion'


//Images
import generalPFP from '../images/generalPFP.png'

//CSS
import '../css/ProfileBanner.css'

function ProfileBanner({firstName, lastName, email, pfp, profileRetrieveError, signOutUser}) {

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    return (
        <motion.div animate={{opacity: [0,1], y:[5, 0]}} id={isMobile ? "banner-container-mobile" : "banner-container-desktop"}>
            {!profileRetrieveError && 
                <div style={{flex: 1, flexDirection: "row", display: "flex"}}>
                    <img src={pfp ? pfp : generalPFP} alt="Google Account Profile"/>
                    <div id={isMobile ? "name-email-container-mobile" : "name-email-container-desktop"}>
                        <p style={{fontSize: isMobile ? undefined : "1.15vw", marginBottom: isMobile ? undefined : 0, fontWeight: 500}}>{firstName} {lastName}</p>
                        <p>{email}</p>
                    </div>
                </div>
            }
            {profileRetrieveError &&
                <div style={{flex: 1, flexDirection: "row", display: "flex"}}>
                <img src={pfp} alt="Google Account Profile"/>
                <div id={isMobile ? "name-email-container-mobile" : "name-email-container-desktop"}>
                    <p style={{fontSize: isMobile ? undefined : "1.15vw", marginBottom: isMobile ? undefined : 0, fontWeight: 500}}>Could not retreive profile information</p>
                </div>
            </div>
            }
            <motion.button whileHover={{opacity:[1, 0.75]}} onClick={signOutUser}><p>Sign Out</p></motion.button>
        </motion.div>
    )
}

export default ProfileBanner