import React from 'react'
import {useState, useEffect} from 'react'
import { useMediaQuery } from 'react-responsive';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4'

//CSS
import "../css/CreateAccount.css"

//Firebase
import { signInWithEmailAndPassword, createUserWithEmailAndPassword} from 'firebase/auth';
import { auth, database } from '../firebase/firebaseClient';
import {setDoc, doc } from 'firebase/firestore';
import {onAuthStateChanged} from 'firebase/auth';

//Images
import showPasswordIcon from '../images/showPasswordIcon.png'
import hidePasswordIcon from '../images/hidePasswordIcon.png'
import initialsStoreLogo from '../images/TPSStore.png'
import storeIcon from '../images/storeIcon.png'

function CreateAccount() {

    //Auth
    const [isAuthed, setIsAuthed] = useState(false);

    //General
    const [processing, setProcessing] = useState(false);
    ReactGA.initialize("G-SZLZLTXLRV");
    
    //Form
    const [email, setEmail] = useState('');
    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState('');
    

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    useEffect(()=>{
        
        ReactGA.send({
            hitType: "pageview",
            page: "/",
            title: "Create Account"
        });

        onAuthStateChanged(auth, async(user)=>{
            if(user){
                setIsAuthed(true);
            }
            else{
                setIsAuthed(false);
            }
        })

        if(!isAuthed){
            document.getElementById("email").addEventListener('focus', (e)=>{
                document.getElementById("email").style="border: 1px solid #E1E1E1; color #000000;";
                document.getElementById("email").attributes.getNamedItem("placeholder").value  = "doe.joe@gmail.com";
                document.getElementById("email").classList.remove("invalid-form");
            });

            document.getElementById("firstName").addEventListener('focus', (e)=>{
                document.getElementById("firstName").style="border: 1px solid #E1E1E1";
                document.getElementById("firstName").attributes.getNamedItem("placeholder").value  = "Joe";
                document.getElementById("firstName").classList.remove("invalid-form");
            });

            document.getElementById("lastName").addEventListener('focus', (e)=>{
                document.getElementById("lastName").style="border: 1px solid #E1E1E1";
                document.getElementById("lastName").attributes.getNamedItem("placeholder").value  = "Doe";
                document.getElementById("lastName").classList.remove("invalid-form");
            });

            document.getElementById("phone").addEventListener('focus', (e)=>{
                document.getElementById("phone").style="border: 1px solid #E1E1E1; color #000000;";
                document.getElementById("phone").attributes.getNamedItem("placeholder").value  = "777-777-7777";
                document.getElementById("phone").classList.remove("invalid-form");
            });

            document.getElementById("password").addEventListener('focus', (e)=>{
                document.getElementById("password").style="border: 1px solid #E1E1E1; color #000000;";
                document.getElementById("password").attributes.getNamedItem("placeholder").value  = "mySecurePassword!8";
                if(isMobile){
                    document.getElementById("password").classList.remove("invalid-form-mobile");
                }
                else{
                    document.getElementById("password").classList.remove("invalid-form");
                }

            });

            document.getElementById("confirmPassword").addEventListener('focus', (e)=>{
                document.getElementById("confirmPassword").style="border: 1px solid #E1E1E1; color #000000;";
                document.getElementById("confirmPassword").attributes.getNamedItem("placeholder").value  = "mySecurePassword!8";
                if(isMobile){
                    document.getElementById("confirmPassword").classList.remove("invalid-form-mobile");
                }
                else{
                    document.getElementById("confirmPassword").classList.remove("invalid-form");
                }

            });
        }

    }, [isMobile, isAuthed]);

    function showHidePassword(){
        setPasswordVisibility(!passwordVisibility);
    }

    function showHideConfirmPassword(){
        setConfirmPasswordVisibility(!confirmPasswordVisibility);
    }

    function validateForm(e){

        setProcessing(true);

        let emptyCheck = false;

        if(email.length === 0){
            document.getElementById("email").style = "border: 1px solid red;";
            document.getElementById("email").attributes.getNamedItem("placeholder").value  = "Please enter a valid email address";
            document.getElementById("email").classList.add("invalid-form");
            emptyCheck = true;
        }

        if(fName.length === 0){
            document.getElementById("firstName").style = "border: 1px solid red;";
            document.getElementById("firstName").attributes.getNamedItem("placeholder").value  = "Please enter a first name";
            document.getElementById("firstName").classList.add("invalid-form");
            emptyCheck = true;
        }

        if(lName.length === 0){
            document.getElementById("lastName").style = "border: 1px solid red;";
            document.getElementById("lastName").attributes.getNamedItem("placeholder").value  = "Please enter a last name";
            document.getElementById("lastName").classList.add("invalid-form");
            emptyCheck = true;
        }

        if(password.length === 0){
            document.getElementById("password").style = "border: 1px solid red; color: red;";
            document.getElementById("password").attributes.getNamedItem("placeholder").value  = "Must be 8 characters long, have 1 uppercase letter, 1 lowercase letter, and 1 digit.";
            setPassword("");
            if(isMobile){
                document.getElementById("password").classList.add("invalid-form-mobile");
            }
            else{
                document.getElementById("password").classList.add("invalid-form");
            }
        }

        if(confirmPassword.length === 0){
            document.getElementById("confirmPassword").style = "border: 1px solid red; color: red;";
            document.getElementById("confirmPassword").attributes.getNamedItem("placeholder").value  = "Must be 8 characters long, have 1 uppercase letter, 1 lowercase letter, and 1 digit.";
            setConfirmPassword("");
            if(isMobile){
                document.getElementById("confirmPassword").classList.add("invalid-form-mobile");
            }
            else{
                document.getElementById("confirmPassword").classList.add("invalid-form");

            }
        }

        if(emptyCheck){
            setProcessing(false);
            return;
        }

        if(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email)){
            if(phone.length !== 0){
                if(/^\d{3}-\d{3}-\d{4}$/.test(phone) || /^\d{10}$/.test(phone)){
                    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;

                    if(passwordRegex.test(password)){
                        if(passwordRegex.test(confirmPassword)){
                            if(password === confirmPassword){
                                createUserAccount();
                            }
                            else{
                                document.getElementById("password").style = "border: 1px solid red; color: red;";
                                document.getElementById("password").attributes.getNamedItem("placeholder").value  = "Passwords do not match.";
                                setPassword("");
                                document.getElementById("password").classList.add("invalid-form");
        
                                document.getElementById("confirmPassword").style = "border: 1px solid red; color: red;";
                                document.getElementById("confirmPassword").attributes.getNamedItem("placeholder").value  = "Passwords do not match.";
                                setConfirmPassword("");
                                document.getElementById("confirmPassword").classList.add("invalid-form");
                                setProcessing(false);
                            }   
                        }
                        else{
                            document.getElementById("confirmPassword").style = "border: 1px solid red; color: red;";
                            document.getElementById("confirmPassword").attributes.getNamedItem("placeholder").value  = "Must be 8 characters long, have 1 uppercase letter, 1 lowercase letter, and 1 digit.";
                            setConfirmPassword("");
                            if(isMobile){
                                document.getElementById("confirmPassword").classList.add("invalid-form-mobile");
                            }
                            else{
                                document.getElementById("confirmPassword").classList.add("invalid-form");

                            }
                            setProcessing(false);
                        }
                    }else{
                        document.getElementById("password").style = "border: 1px solid red; color: red;";
                        document.getElementById("password").attributes.getNamedItem("placeholder").value  = "Must be 8 characters long, have 1 uppercase letter, 1 lowercase letter, and 1 digit.";
                        setPassword("");
                        if(isMobile){
                            document.getElementById("password").classList.add("invalid-form-mobile");
                        }
                        else{
                            document.getElementById("password").classList.add("invalid-form");
                        }
                        setProcessing(false);
                    }
                }
                else{
                    document.getElementById("phone").style = "border: 1px solid red; color: red;";
                    document.getElementById("phone").attributes.getNamedItem("placeholder").value  = "Phone number should follow this format: XXX-XXX-XXXX";
                    document.getElementById("phone").classList.add("invalid-form");
                    setProcessing(false);
                    return;
                }
            }
            else{
                if(/^\d{3}-\d{3}-\d{4}$/.test(phone) || /^\d{10}$/.test(phone)){
                    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;

                    if(passwordRegex.test(password)){
                        if(passwordRegex.test(confirmPassword)){
                            if(password === confirmPassword){
                                createUserAccount();
                            }
                            else{
                                document.getElementById("password").style = "border: 1px solid red; color: red;";
                                document.getElementById("password").attributes.getNamedItem("placeholder").value  = "Passwords do not match.";
                                setPassword("");
                                document.getElementById("password").classList.add("invalid-form");
        
                                document.getElementById("confirmPassword").style = "border: 1px solid red; color: red;";
                                document.getElementById("confirmPassword").attributes.getNamedItem("placeholder").value  = "Passwords do not match.";
                                setConfirmPassword("");
                                document.getElementById("confirmPassword").classList.add("invalid-form");
                                setProcessing(false);
                            }   
                        }
                        else{
                            document.getElementById("confirmPassword").style = "border: 1px solid red; color: red;";
                            document.getElementById("confirmPassword").attributes.getNamedItem("placeholder").value  = "Must be 8 characters long, have 1 uppercase letter, 1 lowercase letter, and 1 digit.";
                            setConfirmPassword("");
                            if(isMobile){
                                document.getElementById("confirmPassword").classList.add("invalid-form-mobile");
                            }
                            else{
                                document.getElementById("confirmPassword").classList.add("invalid-form");

                            }
                            setProcessing(false);
                        }
                    }else{
                        document.getElementById("password").style = "border: 1px solid red; color: red;";
                        document.getElementById("password").attributes.getNamedItem("placeholder").value  = "Must be 8 characters long, have 1 uppercase letter, 1 lowercase letter, and 1 digit.";
                        setPassword("");
                        if(isMobile){
                            document.getElementById("password").classList.add("invalid-form-mobile");
                        }
                        else{
                            document.getElementById("password").classList.add("invalid-form");
                        }
                        setProcessing(false);
                    }
                }
                else{
                    document.getElementById("phone").style = "border: 1px solid red; color: red;";
                    document.getElementById("phone").attributes.getNamedItem("placeholder").value  = "Phone number should follow this format: XXX-XXX-XXXX";
                    document.getElementById("phone").classList.add("invalid-form");
                    setProcessing(false);
                    return;
                }
            }
        }   
        else{
            document.getElementById("email").style = "border: 1px solid red; color: red";
            document.getElementById("email").attributes.getNamedItem("placeholder").value  = "Please enter a valid email address";
            document.getElementById("email").classList.add("invalid-form");
            setProcessing(false);
            return;
        }
    }

    function createUserAccount(){
        createUserWithEmailAndPassword(auth, email, password).then((userCredential)=>{
            console.log("CREATION SUCCESSFUL");
            signInNewUser(email, password);
        })
        .catch((error)=>{
            console.log(error.code);
            console.log(error.message);
            if(error.code === "auth/email-already-in-use"){
                alert("This email address is already in use. Please log in.");
                setProcessing(false);
            }
            else if(error.code === "auth/internal-error"){
                setProcessing(false);
                alert("An unexpected error has occured. Please try again later");
            }
            else if(error.code === "auth/network-request-failed"){
                setProcessing(false);
                alert("Please check your internet connection and try again.");
            }
            else if(error.code === "auth/too-many-requests"){
                setProcessing(false);
                alert("Too many requests. Please try again later.");
            }            

        });
    }

    function signInNewUser(emailSelected, passwordSelected){
        signInWithEmailAndPassword(auth, emailSelected, passwordSelected).then((userCredential)=>{
            const user = userCredential.user;
            setDoc(doc(database, "users", `${user.uid}`), 
                {userID: user.uid, email: email, tel: phone, firstName: fName, lastName: lName, company: company, pfp: "",
            }).then(async()=>{
                setProcessing(false);
                window.location.href = `${window.location.origin}/store/profile`;
            })
            .catch((error)=>{
                console.log(error);
                console.log(error.code);
                console.log(error.message);
            });
        })
        .catch((error)=>{
            
            if(error.code === "auth/internal-error"){
                setProcessing(false);
                alert("An unexpected error has occured. Please try again later");
            }
            else if(error.code === "auth/network-request-failed"){
                setProcessing(false);
                alert("Please check your internet connection and try again.");
            }
            else if(error.code === "auth/too-many-requests"){
                setProcessing(false);
                alert("Too many requests. Please try again later.");
            }

        });
    }


    return (
        <div>
            <header id="header">
                {!isMobile &&
                <nav className={"store-nav-desktop"}>
                    <Link to={"/"} id="nav-logo-desktop" style={{ paddingLeft: 16 }}>
                        <img alt="Transparent Software Logo" src={initialsStoreLogo} />
                    </Link>
                    <div id={"store-nav-links-desktop"}>
                        <Link to={"/store"} id="store-link-container">
                            <img id="store-icon-desktop" src={storeIcon} alt="Store Front Icon indicating a link back to the store page"/>
                        </Link>
                    </div>
                </nav>
                }
                {isMobile &&
                    <nav className={"store-nav-mobile"}>
                        <Link to={"/store"} id="logo-link-container">
                        <img src={initialsStoreLogo} alt="Transparent Software Logo" id="nav-logo-mobile" />
                        </Link>
                        <div id={"store-nav-links-mobile"}>
                            <Link to={"/store"} id="store-link-container-mobile">
                                <img id="store-icon-desktop" style={{paddingRight: 8}} src={storeIcon} alt="Store Front Icon indicating a link back to the store page"/>
                            </Link>
                        </div>
                    </nav>
                }
            </header>
            <main id={isMobile ? "create-account-main-container-mobile" : "create-account-main-container-desktop"}>
                <div style={{display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center',}}>
                    <motion.form animate={{opacity: [0, 1], transition: {duration: 0.75}}}>
                            <h3 style={{padding: 0, fontSize: isMobile ? undefined : "1.5vw"}}>Create a TPS Account</h3>
                            <div id={isMobile ? "form-names-container-mobile" : "form-names-container-desktop"}>
                                <div class={isMobile ? "form-item-container-mobile" : "form-item-container-desktop"}>
                                    <label for="firstName">First Name*:</label>
                                    <input id="firstName" type="text" placeholder='Joe' onChange={(e)=>{setFName(e.target.value);}}/>
                                </div>
                                <div class={isMobile ? "form-item-container-mobile" : "form-item-container-desktop"} style={{marginRight: 0,}}>
                                    <label for="lastName">Last Name*:</label>
                                    <input id="lastName" type="text" placeholder='Doe' onChange={(e)=>{setLName(e.target.value);}}/>
                                </div>
                            </div>
                            <div class={isMobile ? "form-item-container-mobile" : "form-item-container-desktop"}>
                                <label for="company">Company:</label>
                                <input id="company" type="text" placeholder='Company Name' onChange={(e)=>{setCompany(e.target.value);}}/>
                            </div>
                            <div class={isMobile ? "form-item-container-mobile" : "form-item-container-desktop"}>
                                <label for="phone">Phone:</label>
                                <input id="phone" type="tel" placeholder='777-777-7777' value={phone} onChange={(e)=>{setPhone(e.target.value);}}/>
                            </div>
                            <div class={isMobile ? "form-item-container-mobile" : "form-item-container-desktop"}>
                                <label for="email">Email*:</label>
                                <input id="email" type="email" placeholder='doe.joe@gmail.com' value={email} onChange={(e)=>{setEmail(e.target.value);}}/>
                            </div>
                            <div class={isMobile ? "form-item-container-mobile" : "form-item-container-desktop"}>
                                <label for="password">Password*:</label>
                                <div class={isMobile ? "password-input-container-mobile" : "password-input-container-desktop"}>
                                    <input id="password" type={passwordVisibility ? "text" : "password"} placeholder='mySecurePassword8!' value={password} onChange={(e)=>{setPassword(e.target.value);}}/>
                                    <motion.img whileHover={{opacity:[1, 0.5]}} src={passwordVisibility ? showPasswordIcon : hidePasswordIcon} onClick={showHidePassword}/>
                                </div>
                            </div>
                            <div class={isMobile ? "form-item-container-mobile" : "form-item-container-desktop"}>
                                <label for="confirmPassword">Confirm Password*:</label>
                                <div class={isMobile ? "password-input-container-mobile" : "password-input-container-desktop"}>
                                    <input id="confirmPassword" type={confirmPasswordVisibility ? "text" : "password"} hidden={false} placeholder='mySecurePassword8!' value={confirmPassword} onChange={(e)=>{setConfirmPassword(e.target.value);}}/>
                                    <motion.img whileHover={{opacity:[1, 0.5]}} src={confirmPasswordVisibility ? showPasswordIcon : hidePasswordIcon} onClick={showHideConfirmPassword}/>
                                </div>
                            </div>
                    </motion.form>
                    <motion.button disabled={processing} whileHover={{opacity: [1, 0.65]}} onClick={validateForm}>{!processing && 'Create Account'}{processing && <div className="createAccountloader"></div>}</motion.button>
                </div>
            </main>
        </div>
    )
}

export default CreateAccount