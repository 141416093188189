import React from 'react'
import { useMediaQuery } from 'react-responsive';

//CSS
import "../css/Checkout.css"

//Images
import constellationItemsIcon from "../images/constellationItemsIcon.jpg"

function ProductCheckoutItem({name, quantity, subscription}) {

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    return (
        <div id={isMobile ? "product-container-mobile" : "product-container-desktop"}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: "center"}}>
                <img src={constellationItemsIcon} id={isMobile ? "product-icon-mobile" : "product-icon-desktop"} alt={`${name} logo preview`}/>
                <div>
                    <h5>{name}</h5>
                    <p>{subscription} / license</p>
                </div>
            </div>
            <p style={{width: '40%'}}> x {quantity} licenses</p>
        </div>
    )

}

export default ProductCheckoutItem;