import useEffect from "react";
import {Link, useRouteError} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';import ReactGA from 'react-ga4'

import errorScientist from '../images/confusedScientist.png';
import icon from '../images/TPSLogo+Initials.png'

import '../css/ErrorPage.css'
import SiteMap from '../Components/SiteMap';

export default function ErrorPage(){
    const error = useRouteError();
    console.error(error);
    ReactGA.initialize("G-SZLZLTXLRV");

    useEffect(()=>{

        ReactGA.send({
            hitType: "pageview",
            page: "/",
            title: "Error Page"
        });

    }, [])

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    });

    return(
        <div id="body" style={{height: isMobile ? '85vh' : undefined}}>
            <div id="fake-nav-container">
                <div>
                    <Link to={"/"} id={isMobile ? "home-link-container-mobile" : "home-link-container"}>
                        <img src={icon} id={isMobile ? "icon-img-mobile" : "icon-img"} alt="Transparent Software Logo"/>
                    </Link>
                </div>
            </div>
            <div id="error-page" style={{width: isMobile ? '85%' : undefined, maxHeight: isMobile ? '70%' : undefined}}>
                <div id="header-container" style={{maxHeight: isMobile ? '35%' : undefined}}>
                    <img style={{width: isMobile ? '40%' : undefined}} id="errorScientist-img" src={errorScientist} alt="Two scientists with questions marks above their heads"/>
                    <h1 style={{fontSize: isMobile ? '7vw' : undefined}}>Something went wrong...</h1>
                </div>
                <p style={{fontSize: isMobile ? '4vw' : undefined, textAlign: 'center'}}>Sorry, an unexpected error has occurred.</p>
                <p style={{fontSize: isMobile ? '3.5vw' : undefined, textAlign: 'center'}}>
                    <i>{error.status} </i>
                    <i>{error.statusText || error.message}</i>
                </p>
            </div>
            <SiteMap isMobile={isMobile}/>
        </div>
    );
}