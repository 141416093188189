import { loadStripe } from "@stripe/stripe-js";

let stripePromise = null;

const initializeStripe = async () => {
    if (!stripePromise) {
        stripePromise = await loadStripe("pk_test_51OJLD4GtSqtIeEARvLgswNu3wnmaqDqRmwu1pLugleSMYxnx4pT1geUobS2UvuU6L3adHvnkVRL3StIQgMn1w7zw00nSsVuJUk");
    }
    return stripePromise;
};

export default initializeStripe;

